<template>
    <div>
        <!--  @close="$emit('closeTips')" -->
        <el-dialog title="说明" :visible.sync="dialogVisible" width="70%" center @close="$emit('update:dialogVisible', false)">
            <div
                class="info"
            >个性化提升练习是指标有“个性化提升”标签的练习，如《卷行天下周测卷+月考卷》，在学生完成训练产生错题后，系统将通过AI人工智能计算针对学生错题和知识薄弱点推送个性化变式题训练，千人千面，有针对性的帮助学生查缺补漏，巩固提升。</div>
            <div class="info">个性化提升练习在原卷练习时间截止且教师批改后由系统自动推送。</div>
            <div class="info">推题范围：针对学生个人得分率在【0%，90%】范围的错题，进行再练推送</div>
            <div class="info">推送练习数量：最少2题，最多不超过原卷试题总量的1/2</div>
            <div class="weight">推送规则：</div>

            <el-table :data="tableData" border style="width: 70%" class="tips-table">
                <el-table-column prop="student" label="" align="center"></el-table-column>
                <el-table-column prop="ease" label="简单题" align="center"></el-table-column>
                <el-table-column prop="middle" label="中等题" align="center"></el-table-column>
                <el-table-column prop="hard" label="难题" align="center"></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ["dialogVisible"],
    data() {
        return {
            tableData: [
                {
                    student: '学优生',
                    ease: '',
                    middle: '拓展题',
                    hard: '巩固题    拓展题'
                },
                {
                    student: '学中生',
                    ease: '拓展题',
                    middle: '巩固题    拓展题',
                    hard: '巩固题'
                },
                {
                    student: '学困生',
                    ease: '巩固题    拓展题',
                    middle: '巩固题',
                    hard: ''
                }
            ]
        };
    },
};
</script>

<style scoped>
.info {
    font-size: 14px;
    color: #4A4A4A;
    margin-bottom: 16px;
}

.info:last-of-type {
    margin-bottom: 20px;
}

.weight {
    font-weight: bold;
    font-size: 14px;
    color: #4A4A4A;
    margin-bottom: 18px;
}

/deep/ .el-dialog__body {
    padding: 40px 60px 68px;
}

.tips-table /deep/ th {
    color: #22B5AD;
}
.tips-table /deep/ tr td:first-child {
    color: #40ACFF;
}
</style>