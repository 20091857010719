<template>
<div>
  <el-dialog
    title="布置练习"
    :visible.sync="centerDialogVisible"
    custom-class="assign-dialog"
    center
    @close="closeModal">
    <div class="assign-main custom-scroll" :class="info.isPersonalized ? 'personal-setting' : '' ">
        <div class="assign-content assign-paper-section" paperid="0">
            <div class="assign-item">
                <div class="assign-item-require-star">*</div>
                <div class="assign-item-title">练习名称：</div>
                <div class="assign-item-content">
                    <input type="text" placeholder="请输入练习名称" class="ass-dlg-input ass-att-input ass-attname" maxlength="50">
                </div>
            </div>
            <div class="assign-item homework-assignmenttype">
                <div class="assign-item-require-star">*</div>
                <div class="assign-item-title">应用类型：</div>
                <div class="assign-item-content"></div>
            </div>
            <div class="assign-item homework-chapter">
                <div class="assign-item-title">所属章节：</div>
                <div class="assign-item-subtitle">（选填）</div>
                <div class="assign-item-content clearfix">
                    <div id="assign-select-container" class="pull-left"></div>
                    <div class="add-chapter pull-left">
                        <span class="add-chapter-icon"></span>添加章节
                    </div>
                </div>
            </div>
            <div class="assign-item homework-time">
                <div class="assign-item-require-star">*</div>
                <div class="assign-item-title">完成时间：</div>
                <div class="assign-item-content">
                    <div class="timepicker-contaner">
                        <span class="icon-calendar"></span>
                        <input type="text" id="ass-workstart" class="ass-dlg-input ass-att-input ass-attstart" onfocus="WdatePicker({dateFmt:'yyyy-MM-dd HH:mm',lang:'zh-cn',readOnly:'readonly',minDate:'%y-%M-(%d-1)',skin:'simplicity',onpicked:function(){$('.Wdate').blur();}})">
                    </div>
                    —
                    <div class="timepicker-contaner">
                        <span class="icon-calendar"></span>
                        <input type="text" id="ass-workend" class="ass-dlg-input ass-att-input ass-attend" onfocus="WdatePicker({dateFmt:'yyyy-MM-dd HH:mm',lang:'zh-cn',readOnly:'readonly',minDate:'%y-%M-%d',skin:'simplicity',onpicked:function(){$('.Wdate').blur();}})">
                    </div>
                </div>
            </div>
            <div class="assign-item homework-correctmethod">
                <div class="assign-item-title">批改方式：</div>
                <div class="assign-item-content">
                    <div value="2" class="mode-item active"><span class="mode-select"></span>教师批改</div>
                    <div value="1" class="mode-item" :class="disabledClass"><span class="mode-select"></span>学生自批</div>
                </div>
            </div>
            <div class="assign-item homework-answerview">
                <div class="assign-item-title">答案公布时间：</div>
                <div class="assign-item-content">
                    <div value="4" class="mode-item active"><span class="mode-select"></span>时间截止后</div>
                    <div value="1" class="mode-item" :class="disabledClass"><span class="mode-select"></span>练习提交后</div>
                </div>
            </div>
            <div class="assign-item homework-makeup">
                <div class="assign-item-title">补做：</div>
                <div class="assign-item-content">
                    <div value="false" class="mode-item active"><span class="mode-select"></span>不允许</div>
                    <div value="true" class="mode-item" :class="disabledClass"><span class="mode-select"></span>允许</div>
                </div>
            </div>
            <div class="assign-item homework-revision">
                <div class="assign-item-title"><span class="help-icon"></span>学生订正：</div>
                <div class="assign-item-content">
                    <div value="3" class="mode-item active"><span class="mode-select"></span>不给答案订正</div>
                    <div value="2" class="mode-item" :class="disabledClass"><span class="mode-select"></span>给答案订正</div>
                    <div value="1" class="mode-item" :class="disabledClass"><span class="mode-select"></span>不需要订正</div>
                </div>
            </div>
            <div class="assign-item homework-choose-score hide">
                <div class="assign-item-title">漏选得分：</div>
                <div class="assign-item-content clearfix">
                    <div class="choose-score-trigger pull-left">设置漏选得分 >></div><span class="choose-score-tips">(若不设置则默认漏选得满分的一半分值）</span>
                </div>
            </div>
            <div class="assign-item homework-student">
                <div class="assign-item-require-star">*</div>
                <div class="assign-item-title">发布给谁：</div>
                <div class="assign-item-content clearfix">
                    <div class="dlg-class-list clearfix custom-scroll"></div>
                    <div class="dlg-class-detail-trigger pull-left">选择指定小组或学生 >></div>
                    <div class="dlg-class-detail-container pull-left" style="display: none;">
                        <div class="dlgmenu-class-list custom-scroll pull-left"></div>
                        <div class="dlgmenu-class-detail pull-left"></div>
                    </div>
                </div>
            </div>
            <div class="personalized" v-if="info.isPersonalized">
                <div class="personal-head"><i @click="showTips = true"></i>个性化提升设置：</div> 
                <div class="personal-radio assign-item-content">
                        <div value="true" class="full-radio mode-item active" @click="checkoutPush(true)">
                            <span class="mode-select"></span>系统智能推送
                            <div class="tips">( 练习时间截止且教师批改后 )</div>
                        </div>
                        <div value="false" class="full-radio mode-item" @click="checkoutPush(false)"><span class="mode-select"></span>不推送</div>
                </div>
                <div class="assign-item homework-deadline">
                    <div class="assign-item-title">截止日期：</div>
                    <div class="assign-item-content">
                        <div class="select-deadline"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <span slot="footer">
        <div class="assign-btn-group">
            <div class="assign-cancel">取消</div>
            <div class="assign-submit">布置</div>
        </div>
    </span>
  </el-dialog>
  <personal-tips v-if="info.isPersonalized" :dialogVisible.sync="showTips"></personal-tips>
</div>
  
</template>
<script>
import PersonalTips from './PersonalTips.vue'

export default {
  name: 'BuZhiModal',
    props:['info'],
    components: {
        PersonalTips
    },
    data () {
        return {
            centerDialogVisible: false,
            showTips: false,
            pushState: true
        }
    },
    watch: {},
    created () {
        this.importPaperFile()
    },
    methods: {
        importPaperFile(){
            // 引入css文件
            var styleSheetList = [
                {name: "css-site", path:"/static/assign/Content/site.css"},
                {name: "css-select", path:"/static/assign/Content/select.css"},
                {name: "css-attachupload", path:"/static/assign/Content/attachupload.css"},
                {name: "css-ztree", path: "/static/assign/Scripts/zTree/zTreeStyle.css"},
                {name: "css-stu2teacher", path: "/static/assign/Content/stu2teacher.css"},
                {name: "css-datepicker", path: "/static/assign/Scripts/DatePicker/skin/WdatePicker.css"},
                {name: "css-assignment", path: "/static/assign/Content/assignment.css"},
            ]
            for(let i=0;i<styleSheetList.length;i++){
                let item = styleSheetList[i];
                this.appendStyleSheetToBody(item.name, $('<link>',{'href': item.path,'rel': 'Stylesheet','type':'text/css'}));
            }
            // 引入js文件
            var scriptList = [
                {name: "js-jquery", path: "/static/assign/Scripts/jquery-3.4.1.min.js"},
                {name: "js-cookie", path: "/static/assign/Scripts/jquery.cookie.js"},
                {name: "js-ana", path: "/static/assign/Scripts/ana.js"},
                {name: "js-site", path: "/static/assign/Scripts/site.js"},
                {name: "js-datepicker", path: "/static/assign/Scripts/DatePicker/WdatePicker.js"},
                {name: "js-ztree", path: "/static/assign/Scripts/zTree/jquery.ztree.core.min.js"},
                {name: "js-assignment", path: "/static/assign/Scripts/assignment.js"},
                {name: "js-missed", path: "/static/assign/Scripts/miss.score.js"},
                {name: "js-dayjs", path: "/static/assign/Scripts/dayjs.min.js"},
            ]
            for(let i=0;i<scriptList.length;i++){
                let item = scriptList[i];
                this.appendScriptToBody(item.name, $('<script>',{'src': item.path, 'type':'text/javascript'}))
            }
            this.centerDialogVisible = true
            this.$nextTick(()=>{
                this.getMissedOptionScores(this.info.Summary, (paper) => {
                    new AssignMent({
                        mode: "paper",
                        paperList: [ // contentType => 试卷 1 答题卡 2
                            {   
                                idx: this.info.Summary.id.id, 
                                version: this.info.Summary.id.version, 
                                contentType: 1, // contentType => 试卷 1 答题卡 2
                                name: this.info.Name, // 试卷名
                                ispersonal: this.info.isPersonalized || false,
                                seriesId: this.info.seriesId || 0,
                                seriesName: this.info.seriesName || ""
                            }
                        ],
                        missedPaper: paper,
                        cancelCall: ()=>{
                            this.$emit('closeBuZhiModal')
                        },
                        callback: ()=>{
                            this.$Notice.success({
                                title: '提示',
                                desc: '发布成功',
                                duration: 2
                            })
                            let params = {
                                "module": "wlist"
                            }    
                            let url = this.getZznScJumpUrl(params)
                            let device = this.getSessionVal('device')
                            if(this.isValid(device) && device == 'forclasswin'){ //客户端环境
                                var $xml = $('<skip>', {
                                    'url':url,
                                    'target': '训练中心'
                                });
                                top.CallCPP($xml.prop('outerHTML'));
                            }else{
                                top.location.href = url
                            }
                        }
                    });
                })
            })
        },
        getMissedOptionScores(contentId, next) {
            var param = {
                id: contentId.id.id,
                version: contentId.id.version,
                contentType: contentId.paperSource
            }
            startLoading();
            fetchServerData_assignment("QuestionTypeClass", "post", "", param, function (data, status) {
                stopLoading();

                next && next(data);
            })
        },
        appendScriptToBody(id, $scriptObj) {
            $scriptObj.attr('id', id)
            if ($("#" + id).length == 0) {
                let $body = $('body')
                let $lastScriptEle = $body.find('script:last')
                if ($lastScriptEle.length > 0) {
                    $scriptObj.insertAfter($lastScriptEle)
                } else {
                    $body.append($scriptObj)
                }
            }
        },
        appendStyleSheetToBody(id, $styleSheet) {
            $styleSheet.attr('id', id)
            if ($("#" + id).length == 0) {
                let $head = $('head')
                let $lastStyleSheetEle = $head.find('style:last')
                if ($lastStyleSheetEle.length > 0) {
                    $styleSheet.insertAfter($lastStyleSheetEle)
                } else {
                    $head.append($styleSheet)
                }
            }
        },
		closeModal(){
			this.$emit('closeBuZhiModal')
		},
        checkoutPush(state) {
            this.pushState = state;
            if (state) {
                $('.homework-correctmethod .mode-item').eq(0).click();
                $('.homework-answerview .mode-item').eq(0).click();
                $('.homework-makeup .mode-item').eq(0).click();
                $('.homework-revision .mode-item').eq(0).click();
            }
        }
    },
    computed: {
        disabledClass() {
            return this.info.isPersonalized && this.pushState ? 'disabled' : '';
        }
    },
};
</script>

<style>
.assign-dialog{
    width: 80%;
    height: 86%;
    top: 6vh;
    margin-top: 0px!important;
}
.assign-dialog .el-dialog__body{
    height: calc(100% - 128px);
    padding-bottom: 0;
}
.assign-dialog .el-dialog__body .assign-main{
    height: 100%;
}
.personal-setting .assign-content {
    position: relative;
}
.personal-setting .assign-item:first-child,
.personal-setting .homework-assignmenttype,
.personal-setting .homework-chapter {
    padding-right: 220px;
}

.assign-main .full-radio {
    display: block !important;
    width: 100% !important;
    margin-bottom: 40px;
    position: relative;
}
.personalized {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 270px;
}
.personal-head {
    font-size: 16px;
    color: #4A4A4A;
    margin-bottom: 22px;
    font-weight: bold; 
    display: flex;
    align-items: center;
}
.personal-head i {
    width: 16px;
    height: 16px;
    display: block;
    background-image: url(/static/images/res/btn_help.png);
    background-size: 48px 16px;
    cursor: pointer;
    margin-right: 8px;
}
.personal-radio {
    padding-left: 24px;
}
.tips {
    position: absolute;
    font-size: 12px;
    color: #9B9B9B;
    padding-top: 5px;
    padding-left: 18px;
}
.custom-scroll{
    overflow-y: auto;
}
.custom-scroll::-webkit-scrollbar {
    width: 4px;
}
.custom-scroll::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-radius: 2px;
}
@media only screen and (max-width: 1500px) {
    .assign-dialog{
        width: 98%;
        height: 93%;
        top: 3vh;
        margin-top: 0px!important;
    }
    .assign-main {
        padding: 6px!important;
    }
    .assign-item{
        margin-bottom: 20px!important;
    }
}
@media only screen and (max-width: 1300px) {
    .assign-main {
        padding: 0px!important;
    }
    .assign-item{
        margin-bottom: 10px!important;
    }
}
</style>
